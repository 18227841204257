<template>
  <div>
    <p>URL: {{ redirectUrl }}</p>
    <p>TYPE: {{ type }}</p>
    ANIMACJA LOGOWANIA
  </div>
</template>
<script>
export default {
  name: 'Login',
  props: {
    redirectUrl: {
      type: String,
      required: false,
      default: '/'
    },
    type: {
      type: String,
      required: true
    }
  },
  created() {
    this.$store.dispatch('completeSignIn').then(v => {
      if (!v) {
        this.$store.dispatch('signIn', this.type)
      } else {
        this.$router.replace(this.redirectUrl)
      }
    })
  },
  mounted() {},
  data() {
    return {}
  }
}
</script>
